:global(.ui.buttons .or) {
    z-index: 2 !important;
}

.menuLeft,
.menuLeftActive {
    position: fixed;
    top: 106px;
    left: 0;
    bottom: 0;
    margin: 0;
    border-radius: 0;
    width: 210px;
    border: 0 1px 0 0 #444;
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    background: var(--nav-background);
    z-index: 1;
}

.menuLeft::-webkit-scrollbar {
    display: none;
}

.menuMobile {
    display: none;
}

@media screen and (max-width: 1024px) {

    .menuLeft,
    .menuLeftActive {
        padding: 71px 0 70px 0;
    }
}

@media screen and (max-width: 454px) {
    .menuLeft {
        display: none;
    }

    .menuMobile {
        display: block;
        position: fixed;
        top: 115px;
        left: 0;
        z-index: 100;
        padding-top: 0;
        overflow-y: auto;
        max-height: calc(100% - 115px);
        animation-duration: 0.5s;
        animation-name: slidein;
    }

    @keyframes slidein {
        from {
            margin-left: -200%;
        }

        to {
            margin-left: 0%;
        }
    }

    .menuMobile::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
    .menuLeft {
        display: none;
    }
}