.livdomForm {
    width: 80% !important;
}

.Align {
    display: flex;
    gap: 15px;
}

.formNext {
    color: #075244 !important;
    background-color: transparent !important;
}

.formNext:hover {
    color: #075244 !important;
    background-color: transparent !important;
}

.formNext>p {
    color: #075244 !important;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: end;
}

.formNext span {
    text-decoration: underline;
}

.greenButton {
    cursor: pointer;
    width: 30%;
    margin: 15px 0 0 0 !important;
    background-color: #63AE71 !important;
    padding: 10px 20px;
    color: #075244 !important;
    border-radius: 5px;
}

.greenButton:hover {
    margin: 15px 0 0 0 !important;
    background-color: #075244 !important;
    color: white !important;

}

@media screen and (max-width: 1024px) {
    .Align {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
    }

    .greenButton {
        width: 75%
    }

    .ui.form .field {
        margin: 0 !important;
    }
}