.Container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
    padding: 40px 10px 150px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listContainer {
    flex: 1 !important;
}

.Title {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
}

.Icon {
    color: var(--kelbongoo-green) !important;
    margin-right: 15px !important;
}

.IconGray {
    color: rgba(0, 0, 0, 0.75) !important;
    margin-right: 15px !important;
}

.Warning {
    background: rgba(64, 118, 32, 0.1);
    border-radius: 4px;
    padding: 15px;
    margin: 15px 0;
}

.availableText {
    background: rgba(64, 118, 32, 0.1);
    border-radius: 4px;
    margin: 15px 0;
    flex: 1 !important;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.availableText>p {
    font-weight: 600;
    text-align: center;
    padding: 0 15px
}

.WarningGray {
    background: #e6e6e6;
    border-radius: 4px;
    padding: 15px;
    margin: 15px 0;
}

.Divide {
    border-bottom: 1px solid #cccccc;
    padding-top: 10px;
    margin-bottom: 10px;
}

.RemovedProducts .ProductName {
    text-decoration: line-through;
    font-weight: 700;
}

.RemovedProducts .ProducerName {
    font-weight: 400;
    color: #555555;
    font-style: italic;
}

.AvailableProducts .ProductName {
    font-weight: 700;
}

.AvailableProducts .ProducerName {
    font-weight: 400;
    color: #555555;
    font-style: italic;
}

.ProducerName {
    font-weight: 400;
    color: #999999;
    font-size: 12px;
    font-style: italic;
}

.ConsumerPrice {
    font-weight: 700;
}

.ProductName {
    font-weight: 700;
}

.TableImgProductContainer {
    position: relative;
    background-color: #cccccc;
    width: 70px;
    height: 70px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 12px;
}

.TableImgProduct {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.TableProduct {
    display: flex;
    align-items: center;
}

.TableQuantity {
    font-weight: 700;
    font-size: 18px;
}

.TableRow td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.TableHeaderCellQuantity {
    width: 120px;
}

.ActionFooter {
    border-top: 1px dashed #cccccc;
    margin: 25px 0 50px 0;
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.LocationChangeContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 740px;
    margin-bottom: 20px;
}

.CurrentLocation {
    width: 100%;
    background: rgb(230, 230, 230);
    border-radius: 4px;
    padding: 15px;
    margin: 15px 0;
    text-align: center;
}

.SelectLocation {
    width: 100%;
    background: rgba(64, 118, 32, 0.1);
    border-radius: 4px;
    padding: 15px;
    margin: 15px 0;
    text-align: center;
}

.tableContainer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 3vw
}

.LocaleName {
    font-size: 1.1rem;
    font-weight: bold;
}

.schedule {
    font-style: italic;
    color: #464646;
}

.LocationIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--kelbongoo-green) !important;
    border-radius: 4px;
    padding: 7px 4px 7px 7px;
}

.LocationIcon {
    color: #ffffff;
}

@media screen and (max-width: 768px) {
    .Container {
        padding: 30px 20px 150px 20px
    }

    .TableHeaderCellQuantity {
        width: 12px;
    }

    .ActionFooter {
        flex-direction: column;
        gap: 10px;
        margin: 25px 0;
    }

    .tableContainer {
        flex-wrap: wrap;
    }

    .listContainer {
        margin-bottom: 25px;
        flex: 0 1 auto !important;
    }
}

@media screen and (min-width: 768px) {
    .Container {
        margin-top: 106px;
        padding: 40px 10px 40px 10px;

    }
}