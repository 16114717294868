.OrderListContainer {
    padding-top: 0;
    margin-bottom: 30px;
}

.UpperViewContainer {
    margin-bottom: 30px;
}

.ItemsListHeaderContainer {
    margin-bottom: 30px;
}
