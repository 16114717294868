/* mobile-first */

/* Container */

:global(.ui.card).Card {
    width: 100%;
    position: relative;
    font-size: 0.862em !important;
}

:global(.ui.card.active).Card {
    background-color: var(--sand);
}

:global(.ui.modal) p {
    white-space: pre-wrap;
    margin: 0 0 1em;
}

.Bio {
    top: 5px;
    left: 5px;
    width: 30px;
    position: absolute !important;
}

@media screen and (max-width: 454px) {
    .Bio {
        top: 0px;
        left: 80px;
        width: 20px;
    }

    :global(.ui.card).Card {
        height: 100px;
    }
}

/* larger screens */

@media screen and (min-width: 455px) and (max-width: 1024px) {
    :global(.ui.card).Card {
        max-width: 47% !important;
        height: 90px;
        margin-bottom: 20px;
    }

    .Bio {
        top: 0px;
        left: 70px;
        width: 20px;
    }
}

@media screen and (min-width: 1025px) {
    :global(.ui.card).Card {
        max-width: 230px !important;
        font-size: 0.862em !important;
    }
}