.container {
    width: 100%;
    text-align: center;
    padding-top: 180px;
}

@media screen and (max-width: 500px) {
    .container {
        padding-top: 5em;
    }
}