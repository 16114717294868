.LastProducts {
    top: 0;
    width: 28px;
    font-size: 1.3em;
    border-radius: 4px;
    color: white;
    position: absolute;
    border-color: var(--orange);
    background-color: var(--orange);
}

@media screen and (min-width: 1025px) {
    .LastProducts {
        top: -1px;
        right: -1px;
        width: 110px;
        font-size: 1em;
    }
}
