.App {
    transition: all 0.5s ease;
    text-align: center;
    height: 100%;
}

.App> :global(.overlay) {
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1180;
}

:global(.mobileMenuOpen).App> :global(.overlay) {
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
}

@media screen and (min-width: 768px) {
    .App> :global(.overlay) {
        opacity: 1;
        background: rgba(0, 0, 0, 0.7);
    }
}