.comboboxContainer {
    width: 100%;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    height: 40px;
}

.comboboxContainerAbsolute {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1100;
    width: 80%;
    background-color: white;
    padding: 10px;
    border-radius: '4px';
    display: flex;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.comboboxPopover {
    margin-top: 10px;
    z-index: 3000;
}

.image {
    width: 30px !important;
    height: 30px !important;
}

.comboboxInput {
    width: 100%;
    border: none;
    font-size: 0.9em;
    outline: none;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.comboboxInput::placeholder {
    color: grey;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.autocompleteContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: #075244;
    padding: 10px 15px;
}

.geolocButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    height: 40px
}

.geolocButton {
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #F2AB00;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 500px) {
    .comboboxContainerAbsolute {
        width: 75%;
        top: 18%;
    }

    .comboboxPopover {
        position: fixed;
        overflow: visible;
    }
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
    .comboboxContainerAbsolute {
        top: 13%
    }
}