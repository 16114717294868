:global(.content).ProductInfo .ProducerName {
    line-height: 1.3em;
    margin-bottom: 3px;
    margin: 0;
    padding: 0;
}

.LinkToZoom {
    cursor: pointer;
}

.LinkToZoom :global(.header) {
    line-height: 1.4;
    font-size: larger;
}

.LinkToZoom:hover {
    color: var(--primary-color) !important;
}

@media screen and (max-width: 454px) {
    :global(.content).ProductInfo {
        border-top: 0 !important;
        margin: -20px 0 0 100px !important;
        min-height: 0 !important;
        padding: 5px 70px 5px 10px !important;
        text-align: left !important;
        display: flex !important;
        align-items: center !important;
    }
}

/* larger screens */

@media screen and (min-width: 455px) and (max-width: 1024px) {
    :global(.content).ProductInfo {
        align-items: center !important;
        border-top: 0 !important;
        display: flex !important;
        margin: -5px 0 0 90px !important;
        max-height: 80px !important;
        padding: 3px 3px 3px 7px !important;
        text-align: left !important;
        font-size: 0.8em !important;
        display: flex !important;
        align-items: center !important;
    }
}

@media screen and (min-width: 1025px) {
    :global(.content).ProductInfo {
        border-top: 0 !important;
        font-size: 1em !important;
        margin: -10px 0 10px 0 !important;
        min-height: 105px !important;
        padding: 0 !important;
        text-align: center !important;
    }

    :global(.content).ProductInfo .ProducerName {
        margin: inherit;
        padding: 5px 2px 5px 2px;
        line-height: inherit;
    }

    :global(.content).ProductInfo .ProductName {
        padding: 0 4px;
    }
}
