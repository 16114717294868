.Modal {
    margin-top: 0 !important;
}

.Modal :global(.close.icon) {
    color: #000;
}

.Bio {
    width: 50px;
    border-radius: 4px;
}

@media screen and (max-width: 500px) {
    .Modal :global(.content) {
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
    }
    .Modal :global(.content)::-webkit-scrollbar {
        /* WebKit */
        width: 0px;
        height: 0px;
    }

    .Modal :global(.close.icon) {
        display: none;
    }
}

@media screen and (max-width: 849px) {
    .Bio {
        width: 30px;
    }
}
