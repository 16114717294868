.MenuContainer::-webkit-scrollbar {
    display: none;
}

.MenuContainer {
    padding-left: 48px;
}

.MenuContainer a {
    color: black;
}

.scroller {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right: 10px;
    padding-left: 12.5px;
}

.scroller::-webkit-scrollbar {
    display: none;
}