.Container {
    width: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Container form {
    width: 400px;
    margin-bottom: 30px !important;
}

@media screen and (max-height: 600px) {
    .Container {
        display: block;
    }

    .Container form {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 500px) {
    .Container {
        width: 400px;
        text-align: center;
        margin-left: calc(50% - 200px);
        padding: 20px;
        text-align: left;
        margin-top: 115px;
    }
}