/* Image */

.ImageContainer {
    width: 100px;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    position: absolute
}

.ImageContainer:hover {
    opacity: 0.5;
}

.ImageContainer .Image {
    object-fit: contain;
    border-radius: 3px !important;
}

@media screen and (min-width: 455px) and (max-width: 1024px) {
    .ImageContainer {
        width: 90px;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        position: absolute
    }    
}

@media screen and (min-width: 1025px) {
    /* image */

    .ImageContainer {
        width: 230px; /*290px;*/
        height: 230px; /*290px;*/
        max-width: inherit;
        min-width: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
    }

    .ImageContainer .Image {
        max-width: 230px; /*290px;*/
        max-height: 230px; /*290px;*/
    }
}
