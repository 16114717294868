.TheMap {
    position: relative;
    flex: 3;
    height: 100%;
    width: 100%;
    align-content: flex-start;
    align-items: center;
}

.cardList {
    overflow-y: auto;
    direction: ltr;
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.livdomNew {
    height: 35px;
    margin-left: 3px;
    transform: rotate(15deg);
    margin-top: -3px;
}

.dayButton {
    border: solid 1px white;
    border-radius: 20px;
    color: white;
    padding: 5px 15px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.emptyMention {
    color: #075244;
    font-weight: bold;
    margin-top: 50px !important;
    font-size: 20px !important;
    width: 50%
}

.dayButton:hover {
    background-color: #F2AB00;
}

.dayButtonSelected {
    border: solid 1px white;
    border-radius: 20px;
    background-color: #F2AB00;
    color: white;
    padding: 5px 15px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pageTitle {
    color: #075244;
    font-size: 18px;
    font-weight: 600;
}

.closeDayFilter {
    margin: 0 0 0 10px;
    width: 10px;
    height: 10px;
}

.datePickerContainer {
    background-color: #075244;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 15px;
    border-radius: 0 0 10px 10px;
}

.headTitle {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding: 0 0.3em;
    margin: 1.5em 0.5em 0.5em 0.5em !important;
    color: #075244;
}

.expandContainer {
    display: none;
}

.Magasins {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    height: calc(-106px + 87vh);
}

.pointRetrait {
    color: #075244;
    font-weight: bold;
    font-size: 16px;
    overflow: hidden;
    display: inline;
    margin-bottom: 0;
    margin-right: 10px;
}

.MobileMagasins {
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
    margin-top: -30vh;
    z-index: 1100;
}

.MagasinsBoutique {
    margin-top: -50vh;
    z-index: 1100;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
}

.displayMapContainer {
    position: fixed;
    bottom: 5%;
    /* Adjust this value as needed to set the desired distance from the bottom */
    left: 50%;
    transform: translateX(-50%);
    background-color: #075244;
    /* Adjust the background color as needed */
    padding: 0.9em 3em;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 998;
    /* Make sure it's on top of other elements */
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mobileParallax {
    width: 100%;
    height: calc(100% - 31px);
    text-align: center;
}

.displayMapContainerText {
    color: white;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.2em;
    margin-right: 0.5em;
}

.button {
    cursor: pointer;
    background-color: #63AE71;
    border-radius: 5px 5px 0 0;
    padding: 0.8em 1.5em;
    color: #075244;
    margin: 0;
    font-size: 1.1em;
    font-weight: 600;
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonSelected {
    cursor: pointer;
    background-color: #075244;
    border-radius: 5px 5px 0 0;
    padding: 0.8em 1.5em;
    color: white;
    margin: 0;
    font-size: 1.1em;
    font-weight: 400;
    margin-top: -4px;
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlayBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1101;
}

.popupContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    flex-direction: column;
    opacity: 0.95;
    z-index: 1102;
}

.popupContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: lightblue;
    flex-direction: column;
    z-index: 1103;
    border-radius: 20px 20px 0 0;
    background-size: cover;
    background-image: url("../../../../assets/nuage_KBG.svg");
}

.popupClose {
    cursor: pointer;
}

.popupText {
    font-size: 16px;
    margin-right: 10px;
    border-bottom: 1px solid white;
    margin-top: 2em;
    padding-bottom: 2em;
    position: relative;
    z-index: 1104;
    color: black;
}

.lastPopupText {
    font-size: 16px;
    margin-right: 10px;
    color: black;
    margin-top: 2em;
    padding-bottom: 2em;
}

.closeIcon {
    align-self: center;
    margin-bottom: 2em;
}

@media screen and (max-width: 1024px) {

    .datePickerContainer {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;
        border-radius: 0 0 5px 5px;
    }

    .livdomNew {
        display: none;
    }

    .closeDayFilter {
        margin: 0 10px 0 10px;
    }

    .datePickerContainer::-webkit-scrollbar {
        display: none;
    }

    .dayButton:hover {
        background-color: transparent;
    }

    .pageTitle {
        font-size: 14px;
        padding: 0 20px;
    }

    .headTitle {
        display: none
    }

    .cardList {
        padding-top: 5px;
        margin-right: 0;
    }

    .mobileParallax {
        height: 100%;
    }

    .TheMap {
        width: 100%;
    }

    .MobileMagasins,
    .MagasinsBoutique {
        position: relative;
        width: 100%;
        transform: translateY(calc(100% + 54vh));
        left: 0;
        height: auto;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .headTitleMobile {
        font-weight: bold;
        text-align: left;
        color: #075244;
        margin-top: 0.6em !important;
        margin-bottom: 0.5em;
        font-size: 15px;
        padding: 0 1em;
    }

    .Magasins {
        top: 100%;
        width: 100%;
        height: auto;
        z-index: 1100;
        padding-inline: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .expandContainer {
        display: flex;
        cursor: pointer;
        margin-top: 1.5em;
        margin-bottom: 1em;
    }

    .pointRetrait {
        font-size: 20px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 500px) {

    .MobileMagasins,
    .MagasinsBoutique {
        box-shadow: rgba(0, 0, 0, 0.5) 0 0 25px;
        transform: translateY(calc(100% + 44vh));
    }

    .TheMap {
        width: 100%;
    }
}