:local(.familyDivider) {
    background: white;
    /* position: sticky; */
    top: 88px;
    padding: 5px 0;
    z-index: 2;
}

:local(.familySubtitle) {
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
    margin-top: -20px;
    font-size: 1em;
    font-style: italic;
}
