.MainView {
    position: sticky;
    top: 70px;
    right: 0;
    margin-left: 0;
    z-index: 3;
    background: white;
    border-bottom: 1px dashed #ccc;
}

.InlineHeader {
    display: inline;
    font-size: 20px !important;
}

.Filter {
    position: absolute;
    right: 9px;
}

.headerTile {
    font-size: 0.8em;
    padding: 6px;
    margin: 0 6px;
}

.Cart {
    margin: 0 0 0 10px !important;
    position: relative;
    right: 10px;
    top: 10px;
}

@media screen and (min-width: 1025px) {
    .Icon {
        display: none !important;
    }

    .MainView {
        margin-left: 210px;
        padding: 10px 0px;
        top: 0px;
        margin-top: 106px;
        min-height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 499px) {
    .InlineHeader {
        font-size: 1.15em !important;
    }
}

@media screen and (max-width: 1024px) {
    .MainView {
        left: 0;
        padding: 10px 0;
    }

    .MainView :global(h1.ui.header) {
        font-size: 1.1rem;
    }

    .MainView :global(h2.ui.header) {
        font-size: 1rem;
    }

    .Icon {
        position: absolute;
        transition: all 0.5s ease;
        top: 8px;
        left: 10px;
        cursor: pointer;
    }

    .mobileMenuOpen :global(i.inverted.green.icon).Icon {
        color: black !important;
    }

    .Title {
        margin: 0 5px;
    }
}