.ImageRow {
    margin-top: 150px;
}

.Upper {
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.OrderLink {
    font-weight: bold;
    font-size: 1.3em;
}

:global(.ui.icon.message).Message {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 500px) {
    .ImageRow {
        margin-top: 50px;
    }
}