:global(a.item).FamilyItem {
    transition: all 0.5s ease;
}
:global(a.item).FamilyItem:hover {
    color: var(--kelbongoo-green);
}

:global(a.item.active).FamilyItem {
    background-color: rgba(0, 0, 0, 0.15);
    color: black;
    transition: all 0.5s ease;
}

:global(a.item.active).FamilyItem:hover {
    color: var(--kelbongoo-green);
}
