.cardContainer {
    display: flex;
    width: 95%;
    border-radius: 20px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    align-self: center;
    direction: ltr;
    margin: 1% 0;
    padding: 3%;
    flex-direction: column;
    text-align: left;
    border: 2px solid transparent;
    scroll-margin-top: 10px;
}

.cardContainerSelected {
    display: flex;
    width: 95%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-self: center;
    direction: ltr;
    margin: 1% 0;
    padding: 3%;
    flex-direction: column;
    text-align: left;
    border: 2px solid #075244;
    scroll-margin-top: 10px;
}

.cardContainerNew {
    display: flex;
    width: 95%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-self: center;
    direction: ltr;
    margin: 1% 0;
    padding: 3%;
    flex-direction: column;
    text-align: left;
    border: 3px solid orange;
    scroll-margin-top: 10px;
}

.cardContainerPrivateLocale {
    display: flex;
    width: 95%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-self: center;
    direction: ltr;
    margin: 1% 0;
    padding: 3%;
    flex-direction: column;
    text-align: left;
    border: 3px solid #075244;
    scroll-margin-top: 10px;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.pointRetrait {
    color: #075244;
    border-bottom: 1px solid #075244;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
    display: inline;
    margin-bottom: 0;
    margin-right: 10px;
}

.dayOpenedContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dayContainerGreen {
    width: 18px;
    background-color: #6A978E;
    border-radius: 20px;
    height: 18px;
    align-items: center;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
    align-content: center;
    font-size: 12px;
}

.dayContainerGrey {
    width: 18px;
    background-color: #E2E8F0;
    border-radius: 20px;
    height: 18px;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 12px;
}

.address {
    color: #075244;
    font-size: 14px;
    margin-left: 0.5em;
    font-weight: 400;
}

.flex {
    display: flex;
    margin-top: 10px;
}

.cardIcons {
    width: 24px;
    height: 24px;
}

.cardIconsLarger {
    width: 28px;
    height: 28px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sideImage {
    height: 30px;
    margin-top: -7.5px;
}

.title {
    color: #075244;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px !important;
    margin-right: 5px !important;
    cursor: pointer;
}

.iconButton {
    font-size: 15px;
    height: fit-content;
    color: white;
    padding: 5px 10px;
    background-color: #075244;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.iconButton>p {
    font-size: 12px;
}

.undertitle {
    color: #075244;
    font-size: 14px;
    margin-left: 0.6em;
    margin-bottom: 0;
    font-weight: 400;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.expandContainer {
    display: flex;
    cursor: pointer;
}

@media screen and (min-width: 500px) {
    .infoContainer {
        min-height: 140px
    }
}

@media screen and (max-width: 500px) {

    .title,
    .undertitle,
    .address,
    .pointRetrait {
        font-size: 16px;
    }

    .innerContainer {
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
    }

    .infoContainer {
        margin-top: 1.5em;
    }

    .expandContainer {
        margin-bottom: 1em;
        align-self: center;
    }

    .cardContainer,
    .cardContainerSelected,
    .cardContainerNew,
    .cardContainerPrivateLocale {
        padding: 15px;
        margin: 10px 0;
    }
}