/* details */

.PriceLabelContainer {
    text-align: right;
}

.PriceLabelContainer > .PriceLabel {
    width: auto;
    right: -3px;
    top: -5px;
    background-color: #d1d1d1;
}

@media screen and (min-width: 1025px) {
    .PriceLabelContainer {
        text-align: inherit;
        margin: 2px 0 0 0;
    }

    .PriceLabelContainer > .PriceLabel {
        width: auto;
        right: 0;
        margin: 10px 0 0 0 !important;
        font-size: 1rem;
    }
}

@media screen and (min-width: 455px) and (max-width: 1024px) {
    .PriceLabelContainer {
        margin-top: -8px
    }
}