.PersistentCheckoutBar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: white;
    border-top: 1px dashed #ccc;
    padding: 20px 90px 20px 10px;
    display: flex;
    justify-content: space-between;
}

.ExpandingButtonDisabled {
    opacity: 0.5
}

@media screen and (max-width: 480px) {
    :global(.ui.button).ExpandingButton {
        width: 90%;
        margin-bottom: 3px;
        height: 100%
    }

    :global(.ui.button).ExpandingButtonDisabled {
        width: 90%;
        margin-bottom: 3px;
    }

    .PersistentCheckoutBar {
        padding-right: 75px;
    }
}