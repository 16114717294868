.Container {
    width: 100%;
    padding: 40px 10px;
}

.Container form {
    width: 400px;
    /* display: table-cell; */
    vertical-align: middle;
    margin: 0 auto;
}

.Container :global(.button-container) {
    margin-top: 3em;
}

:global(.ui.header) :global(.sub.header).Highlight {
    color: var(--primary-color);
    margin: 5px 0 0 0;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .Container {
        width: 400px;
        height: calc(100% - 55px);
        display: table;
        text-align: center;
        margin-left: calc(50% - 200px);
        padding: 20px;
        text-align: left;
        margin-top: 115px
    }

    .includeAddress.Container {
        width: inherit;
        max-width: 1000px;
        margin: 115px auto 0 auto;
    }

    .includeAddress.Container form {
        width: 100%;
        margin: 0 auto;
    }

    :global(.button-container) {
        margin-top: 50px;
    }

    .Container :global(.login-container) {
        margin-top: -42px;
    }
}