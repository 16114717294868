:global(.ui.container).DeliveryContainer {
    font-size: 0.9em;
    margin-bottom: 35px;
}

.livdomContainer {
    margin-top: 7px;
}

@media screen and (min-width: 768px) {
    :global(.ui.container).DeliveryContainer {
        font-size: inherit;
        margin-bottom: 0;
    }

    .livdomContainer {
        display: flex;
    }
}