:global(.card).Card {
    width: 100%;
    min-height: 100px;
    position: relative;
    font-size: 0.862em !important;
}

:global(.card).Card.active {
    background-color: var(--sand);
}

.CardHeader {
    text-align: left;
    padding: 0 0.4em !important;
}

.CardHeader .name {
    font-weight: 700;
    font-size: 1.1em;
    line-height: 1.1em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.85);
}

.CardBody {
    text-align: center;
}

/* Image */

:global(.card) .ImageContainer {
    width: 80px;
    max-height: 80px;
    border-radius: 3px !important;
    overflow: hidden;
    cursor: pointer;
    float: left;
    margin: 0 12px 12px 0;
}

:global(.card) .DescriptionContainer {
    font-style: italic;
    text-align: right;
    height: 95px;
    line-height: 16px;
    font-size: 0.8em;
}

@media screen and (min-width: 455px) and (max-width: 768px) {
    :global(.card).Card {
        max-width: 210px !important;
    }
}

@media screen and (min-width: 768px) {
    :global(.card).Card {
        max-width: 230px !important;
        font-size: 0.862em !important;
    }

    .CardHeader {
        padding: 0.4em 1em !important;
        font-size: 1.2em;
    }

    :global(.card) .ImageContainer {
        width: initial; /*290px;*/
        height: initial; /*290px;*/
        max-width: initial;
        max-height: initial;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        float: none;
        margin: 0 0 10px 0;
        overflow: inherit;
    }

    :global(.card) .DescriptionContainer {
        text-align: center;
        height: 80px;
    }

    :global(.card) .DescriptionContainer > table {
        width: 100%;
        height: 100%;
        line-height: 20px;
        font-size: 1.1em;
    }
}
