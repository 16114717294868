.MenuHeader {
    cursor: pointer;
}

.MenuHeader:hover {
    color: var(--kelbongoo-green);
    transition: all 0.5s ease;
}

:global(.menu).FamilyMenu {
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
}

:global(.item).CategoryItem:not(.active)> :global(.header).CategoryItemHeader {
    margin: 0 !important;
}

.CategoryItemHeader:hover {
    color: #F2AB00 !important;
}

:global(.item).CategoryItem:not(.active)> :global(.menu).FamilyMenu {
    margin: 0 !important;
}

:global(.item.active).CategoryItem> :global(.menu).FamilyMenu {
    height: auto;
    margin: auto;
}