.ModalContent {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, .6)
}

.ModalContentVersion {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fdfbec !important;
}

.ModalActionsVersion {
    background-color: #fdfbec !important;
}

.VersionUpdate {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    color: #075244;
    font-size: 15px !important;
}

.VersionUpdate h3 {
    color: #075244 !important;
    font-size: 24px !important;
}