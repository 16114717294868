.Container {
    width: 100%;
    text-align: center;
    margin-top: 150px;
}

.ImageContainer {
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Image {
    width: 150px;
    height: auto;
}

.HomeDeliveryTextContainer {
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 1.3em;
}

.BoutiquesTextContainer {
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 1.3em;
}

@media screen and (max-width: 500px) {
    .Container {
        margin-top: 3em;
    }
}