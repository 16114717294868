.contentRight {
    background: white;
    width: 100%;
    padding-top: 15px;
}

.contentRightMainImage {
    object-fit: contain;
}

.signupButton {
    margin-top: 10px;
    text-align: center;
}

.textContainer {
    text-align: start;
    font-size: 0.9em;
}

.headerTitle {
    text-align: center;
    margin: -10px 0 10px 0;
    color: rgba(11, 82, 68, 0.87) !important;
    font-size: 20px !important;
}

.headerSubTitle {
    font-weight: bold;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 10px 0px 15px 0px !important;
}

.textContainer p {
    margin: 0 0 0.8em 0 !important;
    font-size: 14px;
}

.imgContainer {
    display: flex !important;
    justify-content: center !important;
    height: max-content !important
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
    .contentRight {
        margin: 0 0 0 220px !important;
        padding: 30px 10px 0 10px;
    }

    .contentRightMainImage {
        width: 60%;
    }

    .headerTitle {
        text-align: start;
    }

    .textContainer {
        margin-right: 220px !important;
    }

    .imgContainer {
        margin-right: 220px !important;
    }
}

@media screen and (min-width: 1025px) {
    .contentRight {
        margin: 0 0 0 220px !important;
        padding: 20px 20px 0 20px;
    }

    .headerTitle {
        text-align: start;
    }
}


@media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
    .contentRight {
        margin: 0 !important;
    }
}