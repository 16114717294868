.Products {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ImgProduct {
    border-radius: 6px !important;
    width: 31px !important;
    height: 31px !important;
}
.ProductName {
    font-weight: bold;
    line-height: 1.2;
}
.ProducerName {
    color: #999;
    font-size: 0.8em;
    font-style: italic;
    line-height: 1.2;
}
