.cardContainer {
    display: flex;
    max-width: 400px;
    align-self: center;
    direction: ltr;
    flex-direction: column;
    text-align: left;
    padding: 0 10px 10px 2px;
    border: 2px solid transparent
}

.cardContainerNew {
    display: flex;
    max-width: 400px;
    align-self: center;
    direction: ltr;
    padding: 10px;
    flex-direction: column;
    text-align: left;
    padding: 0 10px 10px 2px;
    border: 3px solid orange
}

.cardContainerPrivateLocale {
    display: flex;
    max-width: 400px;
    align-self: center;
    direction: ltr;
    padding: 10px;
    flex-direction: column;
    text-align: left;
    padding: 0 10px 10px 2px;
    border: 3px solid #075244
}

.closeButton {
    position: absolute;
    right: 0px;
    cursor: pointer;
    top: 8px;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.pointRetrait {
    color: #075244;
    border-bottom: 1px solid #075244;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
    display: inline;
    margin-bottom: 0;
    margin-right: 10px;
}

.dayOpenedContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0
}

.dayContainerGreen {
    width: 18px;
    background-color: #6A978E;
    border-radius: 20px;
    height: 18px;
    align-items: center;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
    align-content: center;
    font-size: 12px;
}

.dayContainerGrey {
    width: 18px;
    background-color: #E2E8F0;
    border-radius: 20px;
    height: 18px;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 12px;
}

.address {
    color: #075244;
    font-size: 13px;
    margin-left: 0.5em;
}

.flex {
    display: flex;
    margin: 5px 0;
}

.cardIcons {
    width: 18px;
    height: 18px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sideImage {
    height: 30px;
    margin-top: -7.5px;
}

.title {
    color: #075244;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.8em !important;
    margin-right: 5px !important;
    cursor: pointer;
}

.undertitle {
    color: #075244;
    font-size: 13px;
    margin-left: 0.6em;
    margin-right: 5px;
    margin-bottom: 0;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
}

.expandContainer {
    display: flex;
    cursor: pointer;
}

@media screen and (max-width: 500px) {

    .dayContainerGreen,
    .dayContainerGrey {
        margin-left: 2px;
        margin-right: 2px;
    }

    .undertitle,
    .address,
    .pointRetrait {
        font-size: 12px;
    }

    .title {
        font-size: 14px;
    }

    .innerContainer {
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
    }

    .expandContainer {
        margin-bottom: 1em;
        align-self: center;
    }

    .dayOpenedContainer {
        margin-top: 10px;
    }
}