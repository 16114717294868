.clusterIcon {
    background: #075244;
    color: #fff;
    border-radius: 100%;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.mapOverlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.375);
    display: flex;
    justify-content: center;
    align-items: center;
}

.livdomBox {
    width: 60%;
    max-height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.connectContainer {
    width: 100%;
    padding: 15px 0 0;
    background-color: #63AE71;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.legendContainer {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background-color: #FDF4D4;
    padding: 10px 20px;
}

.cardIcons {
    margin-left: 10px;
    width: 24px;
    height: 24px;
}

.addressContainer {
    padding: 10px 20px;
    margin-top: 10px;
    width: 50%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
}

.lightButton {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 30px;
    border-radius: 10px;
}

.greenButton {
    cursor: pointer;
    width: 25%;
    margin: 15px 0 0 0;
    background-color: #63AE71;
    padding: 10px 20px;
    color: #075244;
    border-radius: 5px;
}

.subFormContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0 25px 0;
}

.buttonContainer {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 25px;
}

.connectContainerSelected {
    width: 100%;
    padding: 15px 0 0;
    background-color: #075244;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 25px
}

.connectContainer>h4,
.mainContainer>h4,
.formContainer>h4 {
    color: #075244;
}

h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
}

.connectContainerSelected>h4 {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin: 10px 0
}


.formLogo {
    width: 35px
}

.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 0 0
}

.sliderContainer {
    background-color: #FDF4D4;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.clickableLogo {
    width: 25px;
    cursor: pointer;
    margin: 10px 0 20px 0
}

.formNext>p {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: end;
}

.formNext span {
    text-decoration: underline;
}

.Align {
    display: flex;
    gap: 15px;
}

.SideBoutiqueListContainer {
    position: relative;
    z-index: 1148;
    top: 0;
    left: 0;
    width: 25%;
    height: calc(90vh - 106px);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0 5px 5px 0;
    overflow-y: scroll;
    direction: ltr;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0 30px 0
}

.SideBoutiqueListContainer::-webkit-scrollbar {
    display: none;
}

.hide {
    display: none;
}

@media screen and (max-width: 500px) {
    .livdomBox {
        width: 95%;
    }

    .Align {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 1em;
    }

    .addressContainer,
    .buttonContainer {
        width: 90%
    }
}