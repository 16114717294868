.HeaderMenu,
:global(.ui.top.fixed).HeaderMenu,
:global(.menu.ui[class*='top fixed']) {
    background: var(--lighter-kelbongoo-green) !important;
    color: white !important;
    display: flex;
    padding: 30px 3.166vw;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    z-index: 1150;
    border-radius: 0 !important;
    margin: 0 !important
}

:global(.ui.dimmer) {
    z-index: 1500 !important;
}

:global(.ui.form>p) {
    margin: 0;
}

:global(.ui.fluid.button) {
    display: inline;
}

:global(.ui.modal) {
    z-index: 1501 !important;
}

:global(.menu.ui[class*='top fixed'])::after {
    display: none
}

:global(.ui.dimmer).Dimmer {
    display: flex !important;
}

.ui.menu .item {
    padding: 0 !important;
}

.headerIcons {
    height: 100%;
    margin: 0 15px !important;
}

.subListItem {
    margin: 13px 0 1px
}

/* CART TRIGGER */

:global(.ui.menu) :global(.item).Cart {
    padding: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.Cart {
    margin: 0 0 0 10px !important;
    border: solid 2px #075244;
    border-radius: 10px;
    padding: 0 10px;
}

.BurgerMenu {
    background: var(--lighter-kelbongoo-green) !important;
    height: 100%;
    width: 100%;
    margin-top: 70.5px !important;
    color: white !important;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25%;
    z-index: 1180;
}

.BurgerMenu * {
    margin-bottom: 1em;
}

.headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 !important;
    margin: 0 8px !important
}

.headerTitle h1 {
    color: white;
    font-size: 14px;
    letter-spacing: 0.2px;
}

.headerTitle i {
    color: white
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.signIn {
    height: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 45px;
    margin: 0 10px;
}

.logoutModal {
    position: fixed;
    z-index: 1150;
    top: 40%;
    width: 300px;
    left: calc(50% - 150px);
    border: solid 2px #075244;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalButton {
    width: 35px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signIn a {
    border-radius: 4px !important;
    background-color: #F2AB00 !important;
    padding: 5px 10px;
    max-height: 75px;
    max-width: 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signIn span {
    font-weight: bold;
    font-size: 12px;
}

.signInMobile {
    width: 100%;
    height: 100%;
    border: 1px solid #F2AB00;
    height: 80%;
    border-radius: 10px !important;
    align-self: center;
}

.signInMobile a {
    color: #F2AB00;
}

.signIn a {
    color: white;
}

.ui.menu:after {
    display: none !important;
    content: none;
}

.signIn a:after {
    content: '→ ';
}

.VerticalSpacer {
    height: 106px;
}

/* New underline feature */

.hoverUnderlineAnimation h1:hover {
    color: #fef4d4;
}

.hoverUnderlineAnimation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fef4d4;
    transform-origin: bottom right;
    transition: transform 0.1s ease-out;
}

.hoverUnderlineAnimation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* ACCOUNT TRIGGER */

.Account {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0 !important;
    margin-right: 5px;
}

.Account span {
    margin-left: 0.5em;
    color: white;
}

.HeaderMenuDown,
:global(.ui.top).HeaderMenuDown {
    background: var(--lighter-kelbongoo-green) !important;
    color: white !important;
    display: flex;
    padding: 30px 3.166vw;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    z-index: 1150;
    border-radius: 0 !important;
    margin: 0 !important;
    position: sticky;
    top: -106px;
    height: 106px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    width: 100%;
    border-radius: 0;

}

.HeaderMenuUp,
:global(.ui.top).HeaderMenuUp {
    background: var(--lighter-kelbongoo-green) !important;
    color: white !important;
    display: flex;
    padding: 30px 3.166vw;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
    z-index: 1150;
    border-radius: 0 !important;
    margin: 0 !important;
    position: fixed;
    top: -106px;
    height: 106px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    width: 100%;
    border-radius: 0;
}

@media screen and (max-width: 1024px) {
    .Account span {
        display: none;
    }

    .VerticalSpacer {
        height: 70.5px;
    }

    .Cart {
        margin: 0 10px !important;
    }

    .HeaderMenu,
    :global(.ui.top.fixed).HeaderMenu,
    :global(.menu.ui[class*='top fixed']) {
        justify-content: space-between;
        padding: 0;
        height: 70.67px;
    }

    .headerTitle h1 {
        font-size: 20px;
        margin: 0 10px;
    }

    .headerIcons {
        height: 100%;
        margin: 0 5px 0 10px !important
    }
}

@media screen and (min-width: 1025px) {
    .Account {
        padding-top: 100px;
    }
}


@media screen and (max-width: 500px) {
    .Account {
        margin-right: 0px;
    }

    .Cart {
        margin: 0 !important;
    }

    .HeaderMenu,
    :global(.ui.top.fixed).HeaderMenu,
    :global(.menu.ui[class*='top fixed']) {
        height: 70.67px;
    }

    .signIn a:after {
        content: '→ ';
    }

    .VerticalSpacer {
        height: 70.5px;
    }

    :global(.ui.menu) :global(.item).Cart {
        margin: 0px;
    }

    .headerTitle span {
        font-size: 20px;
    }
}