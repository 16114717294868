.sliderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.slider {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 0px;
    width: 100%;
    overflow-x: hidden;
}

.wrapperHolidays {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.slide {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 4px;
    text-align: center;
    transition: background-color 0.3s;
    width: 100%;
    height: 115px;
}

.pointRetrait {
    color: #075244;
    border-bottom: 1px solid #075244;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
    display: inline;
    margin-bottom: 0px;
    margin-right: 10px;
    margin-top: 15px;
}

.subSlide {
    margin-top: 5px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.mention {
    color: #F8AB1C;
    font-size: 12px;
    text-align: center;
    line-height: 1em !important;
}

.mentionBlack {
    color: black;
    font-size: 12px;
    text-align: center;
    line-height: 1em !important;
}

.mentionTransp {
    color: transparent;
    font-size: 12px;
    text-align: center;
    line-height: 1em !important;
}

.slide.active {
    background-color: #ccc;
}

.superSliderContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.date {
    font-size: 13px;
    height: 20px;
    font-weight: bold;
    margin: 5px 0;
    display: flex;
    align-items: flex-start;
    text-wrap: nowrap;
    text-align: center;
    justify-content: center;
}

.selection {
    display: flex !important;
    align-items: center !important;
    border: 1px solid black !important;
    border-radius: 10px !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    padding: 5px !important;
}

.radioSelected input[type="radio"]:checked+.MuiSvgIcon-root {
    color: black;
}

.startMarket {
    background-color: #075244;
    align-self: center;
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    margin: 0 0 15px 0;
    text-align: center;
    width: 80%;
    font-size: 13px;
}

a:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.startMarketText {
    font-weight: bold;
    text-align: center;
    color: white;
}

.chevronIcon {
    cursor: pointer
}

.greyedOut {
    pointer-events: none;
    opacity: 0.6;
    background-color: lightgray;
    border-radius: 10px;
}

.selection input[type="radio"] {
    margin-right: 8px;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: black;
    accent-color: black;
}

.selection input[type="radio"]:checked {
    border: 1px solid #F8AB1C;
    background-color: black;
}

.selection.selected {
    background-color: #F8AB1C;
}

.timeSlot {
    margin-left: 10px;
    font-size: 12px;
}

.signIn {
    width: 80%;
    margin: 0 0 10px 0;
}

.signIn a {
    border-radius: 0.9em !important;
    background-color: #F2AB00 !important;
    padding: 1em 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signIn span {
    font-size: 13px;
    color: white;
    text-align: center
}

@media screen and (min-width: 1000px) {
    .slider {
        align-items: flex-start;
    }
}

@media screen and (max-width: 500px) {
    .slider {
        flex-direction: column;
        padding: 0 1em;
        align-items: center;
    }

    .signIn {
        margin-top: 20px;
        margin-bottom: 0px;
        width: 90%
    }

    .slide {
        flex: 0 0 0px;
        padding: 0;
        margin: 0.4em 0;
        width: 100%;
    }

    .startMarket {
        margin-top: 1em;
        width: 90%
    }

    .chevronIcon {
        width: 60px;
    }

    .selection {
        padding-top: 0;
        padding-bottom: 0;
    }

    .sliderContainer {
        margin-top: 0;
    }

    .pointRetrait {
        margin-bottom: 0px;
    }

    .wrapperHolidays {
        margin: 20px 0px;
    }

    .mention {
        margin-bottom: -10px;
    }
}