.ButtonContainer {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 45px;
}

.Dropdown {
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.Btn {
    display: flex;
    align-items: center;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    border: none;
    margin-left: 2px;
    padding: 10px;
    cursor: pointer;
}

.Text {
    text-align: left;
    font-size: 12px;
    padding-right: 3px;
}

.LocalName {
    font-weight: 700;
}

.LocalDay {
    font-weight: 400;
}

.StoreInformation {
    padding: 12px 20px;
    color: #000000;
    white-space: normal !important;
    max-width: 300px;
    width: 100%;
}

.DropdownMenu {
    padding: 0 20px !important;
    max-height: 274px !important;
}

.BtnChangeStore {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 15px 20px;
    width: 100%;
    min-width: 200px;
}

.BtnChangeStore a {
    width: 100%;
}

.LocaleButton {
    margin: 7px 0 !important;
    padding: 0 3px;
}

.Divider {
    margin: 0 0 0.5em 0 !important;
}

@media screen and (max-width: 500px) {
    .ButtonContainer {
        margin: 0
    }

    .DropdownMenu {
        padding: 0px 5px !important;
    }

    .Btn {
        padding: 0;
        height: 100%;
        background-color: transparent;
    }

    .Dropdown {
        height: 95%;
    }

    .LocalDay {
        font-size: 0.8em;
    }

    .Panel {
        left: -30% !important;
        top: 53px !important
    }
}

@media screen and (min-width: 500px) {
    .ButtonContainer {
        margin: 0 10px;
    }

    .Btn {
        padding: 15px 10px;
        max-height: 55px;
    }

    .Dropdown {
        height: 95%;
    }

    .LocalDay {
        font-size: 0.8em;
    }

    .Panel {
        left: -20% !important;
        top: 50px !important
    }
}

@media screen and (min-width: 1024px) {
    .Panel {
        right: 0 !important;
        top: 58px !important
    }
}