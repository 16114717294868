.contentRight {
    margin: -25px 0 0 0 !important;
    background: white;
}

.productsContainer {
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bottomButton,
.bottomButton:hover,
.bottomButton:active {
    background-color: #55AE68 !important;
    color: #020D0C !important;
    font-size: 0.9em !important;
    font-weight: 600 !important;
    height: 45px !important;
}

.Wrapper {
    position: sticky;
    top: 70px;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 17px 0 20px 0;
}

.buttonWrapper {
    position: absolute;
    bottom: 13px;
    left: 5px;
    cursor: pointer;
    width: 57.5px;
    height: 57.5px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchButton {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(242, 171, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

@media screen and (min-width: 500px) {
    .contentRight {
        margin: 0 0 0 210px !important;
    }
}

@media screen and (max-width: 500px) {
    .contentRight {
        justify-content: center !important;
    }
}