.cartItem {
    cursor: pointer;
    height: 43px;
    margin: 0;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cartPopup {
    margin-right: 10px;
}

a.CartUpdate {
    position: absolute;
    top: 65px;
    right: 0;
}

.CartUpdate> :global(.ui.labeled.icon.button) {
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    background: white !important;
}

.CartUpdate :global(.ui.header) {
    color: white;
}

@media screen and (min-width: 768px) {
    a.CartUpdate {
        top: 85px;
        right: 20px
    }
}

@media screen and (min-width: 1024px) {
    a.CartUpdate {
        position: absolute;
        top: 50px;
        right: 0;
    }
}


@media screen and (max-width: 500px) {
    .cartItem {
        width: 50px;
    }
}