/* quantity */

.QuantityEdit {
    position: absolute;
    top: auto;
    /* width: auto; */
    bottom: -1px;
    right: -1px;
    border-top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
}

:global(.ui.primary.button).QuantityEditButton {
    box-shadow: white 0 0 10px 0;
    margin: 0;
    /* font-size: 1.14285714rem; */
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

@media screen and (min-width: 1025px) {
    :global(.content).QuantityEdit {
        position: inherit;
        margin-top: -29px !important;
        z-index: 1;
    }

    :global(.ui.primary.button).QuantityEditButton {
        box-shadow: white 0 0 10px 0;
        border-radius: 3px;
    }

    :global(.ui.primary.button).QuantityEditButton,
    :global(.ui.buttons:not(.basic):not(.inverted))> :global(.button).QuantityEditButton {
        box-shadow: white 0 0 10px 0;
        border-radius: 3px;
        z-index: 1 !important;
    }

    :global(.ui.huge.buttons) :global(.or).ButtonOr {
        z-index: 1;
    }

    :global(.ui.huge.buttons) :global(.or).ButtonOr::before {
        color: rgb(45, 143, 68);
        z-index: 1;
    }
}