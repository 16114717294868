.MenuContainer::-webkit-scrollbar {
    display: none;
}

.MenuContainer a {
    color: black;
}

.logoBox {
    position: fixed;
    top: 0;
    left: 0;
    height: 105.67px;
    width: 210px;
    background-color: #075244;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 28px;
}