:global(.ui.grid).Table {
    padding: 10px 0;
    margin-bottom: 120px;
}

:global(h2.ui).Header {
    display: inline-block;
    font-size: 1.2em;
}

.CartInfoContainer {
    padding-bottom: 9em;
}

.dangerContainer {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 500px) {
    .CartInfoContainer {
        padding-bottom: 5em;
    }

    .dangerContainer {
        padding: 10px 0;
    }
}

@media screen and (min-width: 768px) {
    .Table {
        padding: 10px 0;
        margin-bottom: 50px;
    }

    .CartInfoContainer {
        padding-top: 30px;
    }
}

@media screen and (min-width: 1025px) {
    .Table {
        width: 849px;
        margin-left: calc(50% - 425px);
        margin-top: 120px;
    }
}