.Container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin-top: 106px;
}

.image {
    height: 18px;
    width: 18px;
}

.HeaderContainer {
    color: var(--gray-600);
    padding: 0.8em 0;
    width: 100%;
    background-color: lightblue;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: space-around;
    z-index: 10;
}

.fontWeightBold {
    font-weight: bold;
    color: black;
    display: flex;
}

.innerTextContainer {
    display: flex;
    width: 50%;
    justify-content: space-around;
}

.MainContainer {
    width: 95%;
    height: 100%;
    text-align: left;
    display: flex;
    padding: 20px 30px
}

.LivdomContainer {
    width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 1300px) {
    .innerTextContainer {
        width: 80%;
    }
}

@media screen and (max-width: 1024px) {
    .HeaderContainer {
        display: none;
    }

    .Container {
        width: 100%;
        height: unset;
        min-height: auto;
        padding: 0;
        display: flex;
        margin-top: 0
    }

    .LivdomContainer {
        width: 800px;
    }

    .MainContainer {
        width: 100%;
        padding: 0;
        margin-top: 15px;
    }
}