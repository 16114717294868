.PersistentCheckoutBar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: white;
    border-top: 1px dashed #ccc;
    padding: 20px 90px 20px 10px;
    display: flex;
    justify-content: space-around;
}

.ConditionsOuter {
    max-width: 45%;
}

.buttonsContainer {
    display: flex;
}

.PersistentCheckoutBar .amount {
    display: none !important;
}

.ConditionsInner {
    margin-top: 5px;
    margin: 0 auto;
    color: #999;
    font-size: 0.8em;
    font-style: italic;
}

@media screen and (max-width: 500px) {
    .PersistentCheckoutBar {
        flex-direction: column;
        padding-right: 10px;
    }

    .buttonsContainer {
        padding-right: 75px;
        margin-top: 10px;
    }

    .ConditionsOuter {
        width: 100%;
        max-width: 100%;
    }

    .ConditionsInner {
        font-size: 0.6em;
        line-height: 0.8;
    }

    :global(.ui.button).ExpandingButton {
        width: 90%;
        margin: 0 auto 3px;
        height: 100%;
    }
}

@media screen and (min-width: 768px) {
    .PersistentCheckoutBar .amount {
        display: inherit;
    }
}