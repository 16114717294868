.Container {
    margin-bottom: 30px;
    text-align: center;
}

.ProducersDetailLinkContainer {
    display: inline-block;
    width: 300px;
    text-align: center;
}
