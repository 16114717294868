.Container {
    width: 100%;
    max-width: 740px;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 50px;
}

.Align {
    display: flex;
    gap: 15px;
}

.Module {
    background-color: rgba(240, 240, 240, 0.473);
    padding: 15px;
    border-radius: 6px;
    border: 1px solid rgba(214, 214, 214, 0.562);
    margin-bottom: 20px;
}

.Title {
    font-weight: bold;
    font-size: 1.5rem;
}

.Street {
    margin-bottom: 1em;
    width: 100%;
}

.City {
    margin-bottom: 1em;
    width: 50%;
}

.PostalCode {
    margin-bottom: 1em;
    width: 30%;
}

.Textarea {
    margin-bottom: 15px !important;
}

.ActionPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PaymentMethodsList {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
}

.PaymentMethods {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 15px;
}

.PaymentMethodsInformations {
    display: flex;
    align-items: center;
    gap: 5px;
}

.Icon {
    color: rgb(97, 97, 97);
    font-size: 1.2rem;
}

.IconCircle {
    font-size: 0.4rem;
    display: flex;
    align-items: center;
}

.ContainerHiddenNumberCard {
    padding: 0 2px;
}

.CardNumber {
    display: flex;
    align-items: center;
    gap: 5px;
}

.DisplayNumberCard {
    font-size: 1.1rem;
}

.ExpirationDate {
    color: rgb(161, 161, 161);
    padding: 0 15px;
    font-size: 0.9rem;
}

input:disabled,
input:disabled:hover,
input:disabled:active,
input:disabled:focus {
    color: black
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media screen and (max-width: 768px) {
    .Align {
        flex-direction: column;
        gap: 0px;
        margin-bottom: 15px;
    }

    .City {
        width: 100%;
    }

    .PostalCode {
        width: 100%;
    }
}