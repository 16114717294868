.ButtonContainer {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 45px;
    margin: 0px 10px
}

.Panel {
    left: -120% !important;
    margin-top: 25px !important;
}

.ButtonMenu {
    padding-right: 0px;
}

.userInformation {
    padding: 12px 20px;
    color: #000000;
    white-space: normal !important;
    max-width: 100%;
}

.UserName {
    font-weight: 700;
    font-size: 13px !important;
    text-transform: capitalize;
}

.Truncate {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MenuButton {
    display: block;
    border-radius: 6px;
    padding: 13px 0;
    font-size: 1.1em;
    color: #2e2e2e !important;
    text-align: center;
    text-transform: capitalize;
    margin: 7px 0 !important;
}

.MenuButton:hover {
    background-color: #efefef;
}

.Divider {
    margin: 0 0 0.5em 0 !important;
}

.BtnLogout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 15px 20px;
}

.DropdownMenu {
    padding: 0 20px !important;
    max-height: 274px !important;
}

@media screen and (max-width: 1023px) {
    .Panel {
        left: -140% !important;
        margin-top: 20px !important;
    }

    .ButtonContainer {
        margin: 0 10px;
    }

    /* .ButtonMenu {
        min-width: 80px;
    } */
}

@media screen and (max-width: 500px) {
    .Panel {
        left: -420% !important;
        margin-top: 15px !important;
    }

    .ButtonContainer {
        margin: 0;
    }
}