.ConditionsContainer {
    padding: 5px 0;
    font-size: 0.8em;
    color: #999;
    line-height: 1.3;
}

.PersistentCheckoutBar .amount {
    display: none !important;
}

.PersistentCheckoutBar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: white;
    border-top: 1px dashed #ccc;
    padding: 20px 90px 20px 10px;
}

@media screen and (max-width: 500px) {
    .PaymentButton {
        margin: 5px 0px;
    }

    .PersistentCheckoutBar {
        padding-right: 85px;
    }
}

@media screen and (min-width: 500px) {
    .ConditionsContainer {
        padding: 10px 0;
    }

    :global(.ui.button).PaymentButton {
        margin: 20px 0 30px;
    }
}

@media screen and (min-width: 768px) {
    .PersistentCheckoutBar .amount {
        display: inherit;
    }

    .ConditionsContainer {
        font-size: inherit;
    }
}