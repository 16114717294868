.logoLarge {
    vertical-align: middle;
    width: auto;
    height: 45px;
    margin-right: 24.5px;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .logoLarge {
        width: 100%;
        height: auto;
        margin: 0;
    }
}