:global(.ui.basic.black.button).localeButton {
    box-shadow: initial !important;
    display: block;
    width: 100%;
    padding: 1em;
    font-size: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

:global(.ui.basic.black.button).localeButton:hover {
    background: #efefef !important;
}
:global(.ui.basic.black.button).localeButton.Active {
    background: #f5f5f5 !important;
    opacity: 1 !important;
}
:global(.ui.basic.black.button).localeButton.Active b.LocalName {
    color: #2e2e2e !important;
}

.LocalDisabled {
    color: #2e2e2e;
    text-transform: capitalize;
}

.DetailSpanDisabled {
    color: #2e2e2e;
    text-transform: italic;
    font-size: 0.9em;
    padding-top: 10px;
    line-height: 25px;
}

.LocalName {
    color: var(--kelbongoo-green);
}
.DetailSpan {
    color: #999;
    font-style: italic;
    font-size: 0.8rem;
}
