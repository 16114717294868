.ListContainer {
    overflow-y: auto;
    overflow-x: none;
    width: 300px;
    margin-right: 5px;
}

.livdomMention {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #F8AB1C;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}

.livdomMention>p {
    margin: 0
}

.warningSign {
    width: 20px !important;
    height: 20px !important;
    margin-right: 8px !important;
}

.livdomCost {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E5E5E5;
    font-weight: bold;
    border-radius: 5px;
}

.livdomCost>p {
    margin: 0
}