.image {
    width: 75%;
    height: 100%;
    margin-right: 20px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    margin-right: 15px;
    min-height: 140px;
}

@media screen and (max-width: 500px) {
    .image {
        width: 100%;
        height: 140px
    }
}