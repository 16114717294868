.MenuHeader {
    cursor: pointer;
}

.MenuHeader:hover {
    color: var(--kelbongoo-green);
    transition: all 0.5s ease;
}

.ScrollerItem {
    margin: 0 !important;
    cursor: pointer;
    background-color: #55AE68;
    color: #075244;
    white-space: nowrap;
    align-items: center;
    padding: 0px 20px;
    height: 42px;
    margin: 0 5px !important;
    display: flex;
    justify-content: space-around;
}

.SelectedScrollerItem {
    margin: 0 !important;
    background-color: #075244;
    color: white;
    white-space: nowrap;
    align-items: center;
    padding: 0px 20px;
    height: 42px;
    margin: 0 5px !important;
    display: flex;
    justify-content: space-around;
}

.categoryTitle {
    font-size: 0.9em;
    font-weight: 600;
    margin: 0;
}

.categoryLogo {
    height: 30px;
    width: 30px;
    margin-right: 12px;
    max-width: fit-content !important;
}

.off {
    display: none;
}

:global(.item).CategoryItem:not(.active)> :global(.menu).FamilyMenu {
    margin: 0 !important;
}

:global(.item.active).CategoryItem> :global(.menu).FamilyMenu {
    height: auto;
    margin: auto;
}