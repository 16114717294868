.CartDetails {
    padding: 30px 0 150px 0
}

.timerContainer {
    display: flex;
    align-items: center;
}

.cartHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.livdomInfoMessage {
    width: 100%;
    padding: 5px 0;
    background-color: #f8ab1c;
    display: flex;
    justify-content: center;
}

.livdomFees {
    width: 100%;
    padding: 10px 20px;
    background-color: #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.livdomFees>p {
    margin: 0
}

@media screen and (min-width: 1024px) {
    .CartDetails {
        margin: 120px 0;
        width: 849px;
        margin-left: calc(50% - 425px);
    }

    .CartDetails :global(h3.ui.header) {
        margin-top: 2em;
    }

    .cartHeader {
        flex-direction: row;
        margin-bottom: 50px;
    }

    .timerContainer {
        max-width: 20%;
        position: absolute;
        right: 20%;
        margin-top: 2em;
    }
}