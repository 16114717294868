body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

#root {
    height: 100%;
}

:root {
    --light-blue: #d0e6f7;
    --medium-blue: #b8e1f7;
    --blue: #9fd5f3;
    --dark-blue: #355e8a;
    --turnup: #852656;
    --purple: #c067a9;
    --dark-brown: #baa06e;
    --brown: #d1b48f;
    --light-brown: #e9d4b1;
    --leather: #9c887a;
    --warm-white: #fdfbec;
    --light-red: #ff5b5b;
    --red: #e31834;
    --dark-red: #a52131;
    --gray-600: rgba(0, 0, 0, .6);
    --kelbongoo-green: #45b06b;
    --kelbongoo-gold: #f8ab1c;
    --lighter-kelbongoo-green: #075244;
    --much-lighter-kelbongoo-green: #075244;
    --lighter-kelbongoo-gold: #fef4d4;
    --much-lighter-kelbongoo-gold: #fef4d4;
    --orange: #e94f24;

    --sand: var(--lighter-kelbongoo-gold);

    --primary-color: var(--kelbongoo-green);
    --secondary-color: var(--kelbongoo-gold);
    --nav-background: var(--warm-white);
    --nav-primary: var(--sand);
}

a {
    color: var(--primary-color);
}

.ui.green.header {
    color: var(--kelbongoo-green) !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: var(--kelbongoo-green) !important;
}

.ui.ordered.steps .step.completed:before,
.ui.steps .step.completed>.icon:before {
    color: var(--kelbongoo-green) !important;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover,
.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
    background-color: var(--lighter-kelbongoo-green) !important;
}

.ui.primary.button:active,
.ui.primary.buttons .button:active {
    background-color: var(--much-lighter-kelbongoo-green) !important;
}

.ui.basic.primary.button,
.ui.basic.primary.buttons .button {
    color: var(--kelbongoo-green) !important;
    border-color: var(--kelbongoo-green) !important;
    box-shadow: 0 0 0 1px var(--kelbongoo-green) inset !important;
    background: 0 0 !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
    opacity: 0.5;
}

.ui.form .field,
.ui.form .input,
.ui.form .field:last-child {
    margin: 7.5px 0
}

.ui.form .button {
    margin: 5px 0
}


.ui.basic.primary.button:hover,
.ui.basic.primary.buttons .button:hover,
.ui.basic.primary.button:focus,
.ui.basic.primary.buttons .button:focus {
    color: var(--lighter-kelbongoo-green) !important;
    border-color: var(--kelbongoo-green) !important;
    box-shadow: 0 0 0 1px var(--kelbongoo-green) inset !important;
    background: 0 0 !important;
}

.ui.basic.primary.button:active,
.ui.basic.primary.buttons .button:active {
    color: var(--kelbongoo-green) !important;
    background: 0 0 !important;
}

.ui.secondary.button,
.ui.secondary.buttons .button {
    background-color: var(--kelbongoo-gold) !important;
}

.ui.secondary.button:hover,
.ui.secondary.buttons .button:hover,
.ui.secondary.button:focus,
.ui.secondary.buttons .button:focus {
    background-color: var(--lighter-kelbongoo-gold) !important;
}

.ui.secondary.button:active,
.ui.secondary.buttons .button:active {
    background-color: var(--much-lighter-kelbongoo-gold) !important;
}

.ui.basic.secondary.button,
.ui.basic.secondary.buttons .button {
    color: var(--kelbongoo-gold) !important;
    border-color: var(--kelbongoo-gold) !important;
    box-shadow: 0 0 0 1px var(--kelbongoo-gold) inset !important;
    background: 0 0 !important;
}

.ui.basic.secondary.button:hover,
.ui.basic.secondary.buttons .button:hover,
.ui.basic.secondary.button:focus,
.ui.basic.secondary.buttons .button:focus {
    color: var(--lighter-kelbongoo-gold) !important;
    border-color: var(--kelbongoo-gold) !important;
    box-shadow: 0 0 0 1px var(--kelbongoo-gold) inset !important;
    background: 0 0 !important;
}

.ui.basic.secondary.button:active,
.ui.basic.secondary.buttons .button:active {
    color: var(--kelbongoo-gold) !important;
    background: 0 0 !important;
}

.visible.transition.ui.page.dimmer {
    display: flex !important;
}

.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

.animate,
a,
a:hover {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.gm-style-iw-chr {
    display: none;
}

.gm-style-iw-d {
    overflow: visible !important;
    max-height: fit-content !important;
    padding-top: 12px !important;
    padding-bottom: 0px !important;
}