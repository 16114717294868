.Container {
    padding: 30px 0;
}

.Container.paymentTypeSelected {
    padding-bottom: 150px;
}

@media screen and (min-width: 500px) {
    .ChoiceContainer {
        padding-bottom: 130px;
    }
}

@media screen and (min-width: 768px) {
    .Container.paymentTypeSelected {
        padding-top: 150px;
        padding-bottom: 50px;
    }
}