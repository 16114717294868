.Container {
    width: 700px;
    margin: 180px auto 0 auto;
}

.HeaderContainer {
    margin: 3em 0 2em 0;
    font-size: 1.5em;
    color: #075244;
    font-weight: bold;
}

.HeaderContainer img {
    width: 190px;
}

.ListContainer {
    width: 400px;
    margin: 0 auto;
}

@media screen and (max-width: 500px) {
    .Container {
        margin: 0 auto;
        width: auto
    }
}