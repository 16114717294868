.Header {
    font-size: 1.5em;
    margin-bottom: 1.5em;
    margin-top: 3em;
    color: #075244;
    font-weight: bold;
}

.CheckoutPaymentCB {
    max-width: 700px;
    margin: 150px auto 0 auto;
}

.CheckoutPaymentCB>:not(:first-child) {
    margin-top: 0.8em;
}

.CheckoutPaymentCB>.HeaderContainer {
    text-align: center;
}

.CheckoutPaymentCB>.HeaderContainer img {
    display: inline;
    margin-right: 20px;
}

@media screen and (max-width: 500px) {
    .CheckoutPaymentCB {
        margin: 0 auto
    }
}