.currentCartQuantity {
    position: absolute;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

:global(.icon:first-child).TriggerIcon {
    margin-right: 0 !important;
    color: #fff;
    transition: all 0.5s ease;
    display: inline;
    font-size: 2.3em;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .currentCartQuantity {
        color: rgb(45, 143, 68);
        top: 14px;
        left: 0px;
        right: 0px;
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .shoppingBagTriggerIcon {
        color: #F2AB00 !important;
        font-size: 1.8em !important;
    }

    .exclamationCircleTriggerIcon {
        color: #d9534f !important;
        font-size: 1.8em !important;
    }

    .panierText {
        color: #fff;
        font-size: 12px;
        margin-top: 18px;
        margin-left: 3px;
    }

    .currentCartQuantity {
        color: #fff;
        position: absolute;
        top: -3px;
        right: -23px;
    }
}