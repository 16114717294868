.PersistentCartCorrectionBar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: white;
    border-top: 1px dashed #ccc;
    padding: 20px 90px 20px 10px;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 480px) {
    :global(.ui.button).ExpandingButton {
        width: 90%;
        margin-bottom: 3px;
        height: 100%
    }

    .PersistentCartCorrectionBar {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-right: 75px;
    }
}