.Container {
    width: 100%;
    padding: 40px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Container>div {
    width: 400px;
    margin-top: 33%;
    margin-bottom: 66%;
}

.StartTime {
    white-space: nowrap;
    text-decoration: underline;
    font-weight: bold;
}

@media screen and (max-height: 600px) {
    .Container {
        display: block;
    }

    .Container>div {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 500px) {
    .Container {
        width: 400px;
        height: calc(100% - 44px);
        margin-left: calc(50% - 200px);
        text-align: center;
        margin-top: 115px;
    }
}