.Container {
    width: 100%;
    padding: 40px 10px;
}

.Container form {
    width: 400px;
    display: table-cell;
    vertical-align: middle;
}

@media screen and (min-width: 500px) {
    .Container {
        width: 400px;
        height: calc(100% - 55px);
        display: table;
        text-align: center;
        margin-left: calc(50% - 200px);
        padding: 20px;
        text-align: left;
        margin-top: 115px;
    }
}