.Container {
    max-width: 740px;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    text-align: left;
}

.PaymentMethodContainer {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-around;
}

.PaymentMethod {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
}

.PaymentMethod:hover {
    opacity: 1 !important;
}

.PaymentMethodSelected {
    border: 1px solid var(--kelbongoo-green);
}

.PaymentMethodNotSelected {
    border: 1px solid rgba(34, 36, 38, 0.15);
    opacity: 0.7;
}

.ContainerIconPaymentSelected {
    position: absolute;
    top: -8px;
    right: -8px;
    border: 1px solid var(--kelbongoo-green);
    border-radius: 100%;
    background-color: white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IconPaymentSelected {
    color: var(--kelbongoo-green);
    margin: 0 !important;
}

.PaymentMethodHeader {
    padding: 20px;
}

.PaymentMethodTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 900;
    font-size: 1.3rem;
}

.PaymentMethodBody {
    margin-top: 10px;
    font-size: 0.9rem;
}

.Notice {
    padding: 15px;
    background-color: rgb(245, 245, 245);
    color: rgb(131, 131, 131);
    font-weight: 300;
    font-size: 11.5px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.Icon {
    color: var(--kelbongoo-green) !important;
    margin-right: 15px !important;
}

.InfoPanel {
    display: flex;
    align-items: center;
    background: rgba(238, 238, 238, 0.568);
    border-radius: 4px;
    padding: 15px;
    margin: 28px 0;
    font-weight: 500;
    color: rgb(97, 97, 97);
    font-size: 12px;
}

@media screen and (max-width: 768px) {
    .Container {
        padding: 0 15px;
    }

    .PaymentMethodContainer {
        flex-direction: column;
    }
}