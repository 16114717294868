.Container {
    width: 100%;
    padding: 0 10px;
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Container>div {
    width: 400px;
    margin-top: 33%;
    margin-bottom: 66%;
    text-align: center;
    padding-top: 3em;
}

.ImageContainer {
    padding-bottom: 30px;
}

.ImageContainer>img {
    width: 300px;
    height: auto;
}

.NextSaleHours {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

@media screen and (max-height: 600px) {
    .Container {
        display: block;
        margin-top: 40px;
    }

    .Container>div {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 500px) {
    .Container {
        width: 400px;
        height: calc(100% - 44px);
        margin-left: calc(50% - 200px);
        text-align: center;
        margin-top: 115px;
    }
}